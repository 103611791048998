<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="accent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="white--text font-weight-bold text-h5"
      v-text="name"
    />

    <v-spacer />

    <!--    <default-notifications />-->

    <default-account />

    <default-logout />
  </v-app-bar>
</template>

<script>
// Utilities
  import { sync } from 'vuex-pathify'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),

      DefaultLogout: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/Logout'
      ),
      DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
      DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
    },

    computed: {
      ...sync('app', ['drawer', 'mini']),
      name: function () {
        return this.$t('drawer.' + this.$route.name)
      },
    },
  }
</script>
